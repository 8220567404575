import React, { useEffect, useContext, useState } from "react";
import { PageSettings } from "../../../routes/setting";
import { useTranslation } from 'react-i18next';
import { useRouter } from "../../../hooks/useRouter";
import {
    Stack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Spacer,
    Divider,
    Center
} from '@chakra-ui/react';
import { Pages } from "../../../routes/page";
import { useDispatch, useSelector } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import { BG_TYPE, _LANGUAGE_OPTION } from "../../../common/constant";
import { getMemberDetails } from "../../../application/action/auth_action";
import { checkBrowser } from "../../../common/util";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _router = useRouter();
    var _navigate = useNavigate();
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const _BOTTOM_BUTTOM = [
        {
            icon: require('../../../assets/images/security/password.png'),
            title: 'CHANGE_PASSWORD'
        },
        {
            icon: require('../../../assets/images/security/password.png'),
            title: 'CHANGE_SECONDARY_PASSWORD'
        },
        {
            icon: require('../../../assets/images/security/password.png'),
            title: 'ACCOUNT_VERIFICATION',
            disabled: !_USER_DATA.isVerficationRequired
        }
    ];

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        _context.toggleBg(BG_TYPE.black);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function settingActionHandler(key) {
        switch (key) {
            case _BOTTOM_BUTTOM[0]['title']:
                _router.navigate(Pages._CHANGE_PASSWORD);
                break;
            case _BOTTOM_BUTTOM[1]['title']:
                _router.navigate(Pages._CHANGE_SECONDARY_PASSWORD);
                break;
                case _BOTTOM_BUTTOM[2]['title']:
                _router.navigate(Pages._ACCOUNT_VERIFICATION);
                break;
            default:
                break;
        }
    }

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={'SECURITY_OR_PRIVACY'} bgColor="linear-gradient(#a94ff1,#9e32dd)" />
                <Stack mt={3}>
                    {
                        _BOTTOM_BUTTOM.map((item, index) => {
                            return (
                                <>
                                    <Button disabled={item.disabled} key={index} h='3rem' variant={'link'} onClick={() => settingActionHandler(item.title)}>
                                        <Flex align={'center'} w='full' paddingInline={2}>
                                            <Image boxSize={'40px'} src={item.icon} mr={5} />
                                            <Text fontSize={'sm'} >{t(item.title)}</Text>
                                            <Spacer />
                                            {!_USER_DATA.isVerficationRequired && item.title == 'ACCOUNT_VERIFICATION'
                                                &&
                                                <Text mr={2} fontSize={'sm'} color={'#f8c763'}>Verified ✔</Text>
                                            }
                                            <Image boxSize={'25px'} transform={'rotate(180deg)'} src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                                        </Flex>
                                    </Button>
                                    <Divider className="divider-horizontal" ml={-4} w={'120%'} />
                                </>
                            )
                        })
                    }
                </Stack>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", minWidth: "32rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "25px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'} mr={10}>{t('SECURITY_OR_PRIVACY')}</Text>
                            {/* <Box className="settings-icon-box" float={'right'}>
                                <Image boxSize={'50px'} src={require('../../../assets/images/security/password.png')} />
                            </Box> */}
                        </div>
                        <Stack spacing={4} mt={10}>
                            <Center>
                                <Box className="settings-icon-box" width={'fit-content'} mb={4} p={4}>
                                    <Image w={20} h={16} src={require('../../../assets/images/graphic-changepw.png')} />
                                </Box>
                            </Center>
                            {
                                _BOTTOM_BUTTOM.map((item, index) => {
                                    return (
                                        <>
                                            <Button key={index} height={16} borderRadius={16} variant={'link'} bg={'linear-gradient(#ac4cfe,#7416ab)'} onClick={() => settingActionHandler(item.title)}>
                                                <Flex align={'center'} w='full' paddingInline={2} paddingBlock={6}>
                                                    <Image boxSize={'40px'} src={item.icon} mr={5} />
                                                    <Text fontSize={'sm'} color={'#f8c763'}>{t(item.title)}</Text>
                                                </Flex>
                                            </Button>
                                        </>
                                    )
                                })
                            }
                        </Stack>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}