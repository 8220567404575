import React, { useEffect, useState, useContext } from "react";
import { PageSettings } from "../../../routes/setting";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Spacer,
    Card,
    CardBody,
    Center,
    HStack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { showCustomDialog, disposeCustomDialog } from "../../../application/action/app_action";
import GeneralTopBar from "../../../component/general-top-bar";
import { ApiKey, BG_TYPE, ValidationPattern } from "../../../common/constant";
import { checkBrowser, createFormBody, stringIsNullOrEmpty } from "../../../common/util";
import { useForm, Controller } from 'react-hook-form';
import CustomInput from "../../../component/input";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { performLogout } from "../../../application/action/auth_action";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";
import Iframe from "react-iframe";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { control, handleSubmit, errors, setValue, clearErrors, watch } = useForm();
    const { t } = useTranslation();
    const userData = useSelector(state => state.authState.userData);
    const [livenessUrl, setLivenessUrl] = useState('');

    useEffect(() => {
        (async () => {
            try {
                var responseJson = await ApiEngine.get(Routes._GET_ACCOUNT_VERIFICATION);

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                setLivenessUrl(responseJson[ApiKey._API_DATA_KEY]['livenessUrl']);
            }
            catch (err) {
                _dispatch(showCustomDialog({
                    success: false,
                    content: err,
                }));
            }
        })();
    }, []);

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={'ACCOUNT_VERIFICATION'} bgColor="linear-gradient(#a94ff1,#9e32dd)" />
                {
                    !stringIsNullOrEmpty(livenessUrl) &&
                    <Iframe
                        url={livenessUrl}
                        cacheEnabled={false}
                        incognito
                        mediaPlaybackRequiresUserAction={false}
                        originWhitelist={['*']}
                        javaScriptEnabled={true}
                        showsVerticalScrollIndicator={false}
                        onNavigationStateChange={(value) => {
                            console.log(value)
                        }}
                        className="web-view-frame"
                    />
                }
            </div>
        )
    } else {
        return (
            <>
                <div style={{ width: "100%", position: "fixed", top: "0", zIndex: "99999", backgroundImage: "linear-gradient(to right, #7416ab, #ac4cfe, #7416ab)", textAlign: "center" }}>
                    <HStack paddingInline={6} paddingBlock={2} spacing={6} maxW={'100rem'} margin={'auto'}>
                        <Image
                            boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                            src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                        <Spacer />
                        <Text textAlign={'center'} mr={10} fontWeight={'bold'}>{t('ACCOUNT_VERIFICATION')}</Text>
                        <Spacer />
                    </HStack>
                </div>
                {
                    !stringIsNullOrEmpty(livenessUrl) &&
                    <Iframe
                        url={livenessUrl}
                        cacheEnabled={false}
                        incognito
                        mediaPlaybackRequiresUserAction={false}
                        originWhitelist={['*']}
                        javaScriptEnabled={true}
                        showsVerticalScrollIndicator={false}
                        onNavigationStateChange={(value) => {
                            console.log(value)
                        }}
                        className="account-verification-web-view-frame"
                    />
                }
                <Footer />
            </>
        )
    }
}